<nav class="bg-white shadow-md">
    <div class="w-full px-4 sm:px-6 lg:px-8">
        <div class="flex items-center justify-between h-16">
            <div class="flex-shrink-0 "></div>
            <div class="flex justify-center flex-grow">
                <button
                        routerLink="sales-order"
                        [class.active]="isActive('sales-order')"
                        class="nav-button mx-2">
                    Sales Order
                </button>
                <button
                        routerLink="sales-order-item"
                        [class.active]="isActive('sales-order-item')"
                        class="nav-button mx-2">
                    Sales Order Item
                </button>
                <button
                        routerLink="production-update"
                        [class.active]="isActive('production-update')"
                        class="nav-button mx-2">
                    Production Quantity
                </button>
            </div>
            <div class="mr-6">
                <button  (click)="logOut()"
                        class="log-button flex items-center">
                    Logout
                </button>
            </div>
        </div>
    </div>
</nav>